import { SAATVA_CLASSIC } from '@/constants/product-codes'
import styles from '@/components/ProductCarouselGallery/ProductCarouselGallery.module.scss'

const IMGIX_VIDEO_URL = process.env.NEXT_PUBLIC_IMGIX_VIDEO_URL

// These values are set for the carousel display.
// Other values are needed for the zoom gallery, but those are overridden in that component.
const videoConfig = {
    [SAATVA_CLASSIC]: {
        carouselIndex: 1, // zero-based index, so second item
        aspectRatio: '4-3',
        className: styles['imageGallery__video-buttons'],
        dataSelector: 'saatva-classic-gallery-video',
        extension: 'mp4',
        filename: 'responsiveness-back',
        folder: 'saatva-classic',
        imgixOptions: {
            fm: 'mp4'
        },
        imgixSubdomain: IMGIX_VIDEO_URL,
        lazyLoadOffset: 1,
        lazyLoadContainerClassName: 'carouselVideoLazyLoadContainer',
        thumbnailAltTag: 'Thumbnail for video of the Saatva Classic mattress',
        type: 'video/mp4',
        videoProps: {
            autoPlay: true,
            muted: true,
            loop: true,
            playsInline: true,
            preload: 'auto', // needs to be auto for Safari
            // 4:3 aspect ratio to reduce CLS, won't actually be this big due to CSS
            width: 800,
            height: 600
        }
    }
}

export default videoConfig
