import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
    useProductData,
    useProductsData,
    useProductState,
    useVariantsData
} from '@saatva-bits/pattern-library.modules.selection'
import { ProductSelectors } from '@saatva-bits/pattern-library.modules.selection'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { Icon } from '@saatva-bits/pattern-library.components.icon'
import { scrollToElement, OFFSETS } from '@saatva-bits/pattern-library.utils.position'

import config from './BundlesProductSelectorsConfig'
import styles from './BundlesProductSelectors.module.scss'

import { Global } from '@/contexts'
import useSelectorProps from '@/hooks/useSelectorProps'
import useDeviceType from '@/hooks/useDeviceType'
import PictureImgix from '@/components/PictureImgix/PictureImgix'

const BundlesProductSelectors = () => {
    const { urlProductCode: productCode } = useContext(Global.Context)
    const selectorProps = useSelectorProps(productCode)
    const { options, relationships } = useProductData(productCode)
    const { isSmallMobile, isSmallDesktop } = useDeviceType('mobile')

    const bundledProductRelations = relationships.find(relation => relation.relationshipLabel == 'bundledProducts')
    const bundledProductsData = useProductsData(bundledProductRelations.relatedProductCodes)
    const bundleSelection = useProductState(productCode)

    const variantsData = useVariantsData(bundleSelection.bundledVariants)

    const bundleAttributes = Object.keys(options)
    const sharedAttributes = getSharedAttributes(bundledProductsData)
    const sharedHiddenAttributes = bundleAttributes.filter(att => !sharedAttributes.has(att))

    const displayProducts = generateDisplayProducts(productCode, bundledProductsData, variantsData)
    const buystackClasses = classNames(styles.wrapper, 'u-paddingVertical--lg')

    const [isExpandedByCode, setIsExpandedByCode] = useState({})

    function handleToggleClick(productCode) {
        const newExpanded = {...isExpandedByCode}
        newExpanded[productCode] = !newExpanded[productCode]
        setIsExpandedByCode(newExpanded)
    }

    const bundleProductWrapperClasses = classNames(
        styles.productWrapper,
        styles.shared,
        {
            [styles.expanded]: isExpandedByCode[productCode]
        }
    )

    return (
        <>
            {/* 
                The code from here to `END` is NOT dynamic and assumes only the `size` attribute is shared.
                If this functionality is desired elsewhere or needs to be dynamic in the future,
                the `ProductSelectors` bit component should be refactored to optionally allow for
                "collapsible toggles" that exhibit this behavior.
            */}
            <div className={styles.sharedSelectionHeader}>
                Select bundle size
                <button
                    className="t-link t-link--independent t-link--action"
                    onClick={() => scrollToElement('spec-and-faq-section', OFFSETS.both, 200)}
                >
                    Specs
                </button>
            </div>
            <div className={bundleProductWrapperClasses} data-selector='shared-bundle-selectors-container'>
                <div className={styles.productHeader} onClick={() => handleToggleClick(productCode)} data-selector='shared-bundle-size-dropdown'>
                    {bundleSelection.size}
                    <button className={styles.toggleButton}>
                        {isExpandedByCode[productCode] ? 'Done' : 'Edit'}
                    </button>
                </div>
                <div className={styles.productBody}>
                    <ProductSelectors                
                        productCode={productCode}
                        customOptionProps={selectorProps}
                        dataSelectorModifier='buystack'
                        swatchesSize='medium'
                        hiddenAttributes={sharedHiddenAttributes}
                        className={styles.sharedProductSelectors}
                    />
                </div>
            </div>
            {/* END */}

            <div id='bundlesProductSelectors' className={buystackClasses}>
                <h2>Customize your bundle</h2>

                { displayProducts.map( (displayProduct, index) => {

                    const productHiddenAttributes = bundleAttributes.filter(
                        att => sharedAttributes.has(att) || !displayProduct.configurableAttributes.includes(att)
                    )

                    const selectedValuesDisplay = generateSelectedValuesDisplay(
                        displayProduct,
                        bundleSelection,
                        productHiddenAttributes
                    )

                    const productWrapperClasses = classNames(
                        styles.productWrapper,
                        {
                            [styles.expanded]: isExpandedByCode[displayProduct.productCode]
                        }
                    )

                    const toggleLabel = isExpandedByCode[displayProduct.productCode] ? 'Done' : 'Edit'
                    const wrapAttributeValues = isSmallMobile || isSmallDesktop
                    const attributesWrapperClasses = classNames(
                        styles.attributesWrapper,
                        {
                            [styles.wrapped]: wrapAttributeValues
                        }
                    )
                    
                    return (
                        <div className={productWrapperClasses} key={`wrapper-${displayProduct.productCode}`} data-selector={`bundled-product-container-${displayProduct.productCode}`}>
                            <div className={styles.productHeader} onClick={() => handleToggleClick(displayProduct.productCode)} data-selector={`bundled-product-dropdown-${displayProduct.productCode}`}>
                                <PictureImgix
                                    imageClassName={styles.productImage}
                                    name={displayProduct.imageName}
                                    folder={displayProduct.imageFolder}
                                    alt={`${displayProduct.name} product image`}
                                />
                                <span className={styles.numberedCircle}>{index + 1}</span>
                                <div className={styles.productCopyWrapper}>
                                    <h3>
                                        {displayProduct.name}
                                        <br/>
                                        {formatCurrency(displayProduct.price)}
                                    </h3>
                                    { !wrapAttributeValues && 
                                        <div className={attributesWrapperClasses}>
                                            {selectedValuesDisplay}
                                        </div>
                                    }
                                </div>
                                <button className={styles.toggleButton}>
                                    { selectedValuesDisplay.length ?
                                        toggleLabel :
                                        <Icon 
                                            name='expand'
                                            className={styles.chevron}
                                            alt='Toggle Details'
                                            description='Toggle Details'
                                            titleId='circleCheckIcon' />
                                    }
                                </button>
                            </div>
                            { wrapAttributeValues && 
                                <div className={attributesWrapperClasses}>
                                    {selectedValuesDisplay}
                                </div>
                            }
                            <div className={styles.productBody}>
                                <p>
                                    {displayProduct.description}
                                    <a className={styles.pdpLink} href={displayProduct.url} target='_blank' data-selector='bundle-product-pdp-link'>
                                        <SvgSprite className={styles.linkSprite} spriteID='icon-link'></SvgSprite>
                                    </a>
                                </p>
                                <ProductSelectors
                                    productCode={productCode}
                                    customOptionProps={selectorProps}
                                    dataSelectorModifier='buystack'
                                    swatchesSize='medium'
                                    hiddenAttributes={productHiddenAttributes}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}


function generateSelectedValuesDisplay(displayProduct, bundleSelection, productHiddenAttributes) {
    // for simplified access below
    const bundleValuesByCode = bundleSelection.attributes.reduce((values, att) =>{
        values[att.code] = att
        return values
    }, {})

    // Iterate over the product attributes to ensure we retain attribute display order from catalog service
    return displayProduct.configurableAttributes.reduce(
        (results, attributeCode) => {
            const notHidden = !productHiddenAttributes.includes(attributeCode)
            const selectedAttribute = bundleValuesByCode[attributeCode]
            const valueSuffix = attributeCode.toLowerCase().includes('height') ? '"' : ''
            if (selectedAttribute && notHidden) {
                const attLabel = displayProduct.attributeLabels[attributeCode]
                results.push(
                    <div className={styles.attribute} key={attributeCode}>
                        {attLabel}: <span className={styles.attributeValue}>{selectedAttribute.value}{valueSuffix}</span> 
                    </div>
                )
            }
            return results
        }, [])
}

/**
 * Reduce the product and variant data to a simplified object with only data required for display.
 * This simplifies display logic, as well as product merging (ie: Frame + Foundation).
 */
function mapProductDataForDisplay(productData, selectedVariant, configuredDisplayData) {
    const attributeLabels = Object.values(productData.options).reduce(
        (result, option) => {
            result[option.code] = configuredDisplayData?.attributeLabels?.[option.code] || option.displayName
            return result
        }, {})

    return {
        productCode: productData.productCode,
        configurableAttributes: productData.configurableAttributes,
        price: selectedVariant.price,
        // Configurable attributes
        name: configuredDisplayData?.name || productData.name,
        description: configuredDisplayData?.description || productData.content.shortDescription,
        url: configuredDisplayData?.url || productData.url,
        imageName: configuredDisplayData?.imageName || `${productData.productCode}-silo-1-1.jpg`,
        imageFolder: configuredDisplayData?.imageFolder || `products/${productData.productCode}/silo`,
        attributeLabels
    }
}


/**
 * Generate the simplified display product objects.
 * This also processes any configuration, including merging products.
 */
function generateDisplayProducts(productCode, bundledProductsData, variantsData) {
    const bundleConfig = config[productCode]

    // Convert all original products to simplified display products
    const initialDisplayProducts = bundledProductsData.map(
        productData => {
            const selectedVariant = variantsData.find(
                variant => variant.productCode == productData.productCode
            )
            const configuredDisplayData = bundleConfig.displayData[productData.productCode]
            return mapProductDataForDisplay(productData, selectedVariant, configuredDisplayData)
        }
    )

    const mergeConfig = bundleConfig.mergeProducts

    // If there are no merged products, just return the initial products
    if (!mergeConfig) {
        return initialDisplayProducts
    }

    const allFound = mergeConfig.productCodes.reduce(
        (allFound, pc) => allFound && initialDisplayProducts.find(product => product.productCode == pc),
        true
    )

    const finalDisplayProducts = []
    if (!allFound) {
        finalDisplayProducts.concat(initialDisplayProducts)
        console.warn(
            `Potential misconfiguration: Not all merging product codes found in bundle ${productCode}:`,
            mergeConfig.productCodes
        )
    } else {
        const productsDataToMerge = initialDisplayProducts.filter(
            product => mergeConfig.productCodes.includes(product.productCode)
        )
        const mergedProduct = mergeDisplayProducts(productsDataToMerge, variantsData, mergeConfig)

        // Populate final products by replacing consolidated products with the
        // merged product while maintaining the original product order.
        let isAdded = false
        initialDisplayProducts.forEach(product => {
            if (mergeConfig.productCodes.includes(product.productCode)) {
                // Push the merged product in place of the first consolidated product found, discard the rest
                if (!isAdded) {
                    finalDisplayProducts.push(mergedProduct)
                    isAdded = true
                }
            } else {
                // Add all non-merging products as they were
                finalDisplayProducts.push(product)
            }
        })
    }
    return finalDisplayProducts
}


/**
 * Merges multiple "display" products into a single merged product as defined by the provided configuration.
 */
function mergeDisplayProducts(productsToMerge, variantsData, mergeConfig) {
    return productsToMerge.reduce(
        (resultProduct, mergingProduct) => {
            const selectedVariant = variantsData.find(
                variant => variant.productCode == mergingProduct.productCode
            )
            if (resultProduct) {
                // Merge options to ensure they are all accounted for, using Set to eliminate duplicates
                const mergedAttributesSet = new Set([
                    ...(resultProduct.configurableAttributes),
                    ...(mergingProduct.configurableAttributes) 
                ])
                resultProduct.configurableAttributes = Array.from(mergedAttributesSet)
                resultProduct.attributeLabels = { ...(resultProduct.attributeLabels), ...(mergingProduct.attributeLabels) }
                resultProduct.price += selectedVariant.price
            } else {
                // First one, "clone" to create the original merged item and configured display data
                resultProduct = { ...mergingProduct, ...mergeConfig.displayData }
                resultProduct.price = selectedVariant.price
                resultProduct.productCode = mergeConfig.productCodes.join('_') // used for component keys
            }
            return resultProduct
        }, false) // initialize the accumulator to false to simplify logic. Converted to a product in the first pass.
}


/**
 * Finds any attributes that are on more than one bundles product.
 */
function getSharedAttributes(bundledProductsData) {
    const foundAttributes = new Set()
    const sharedAttributes = new Set()
    bundledProductsData.map( productData => {
        productData.configurableAttributes.map( att => {
            if (foundAttributes.has(att)) {
                sharedAttributes.add(att)
            } else {
                foundAttributes.add(att)
            }
        })
    })
    return sharedAttributes
}


BundlesProductSelectors.propTypes = {
    productCode: PropTypes.string
}

export default BundlesProductSelectors
