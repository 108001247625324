import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import styles from './InfoTooltip.module.scss'

const InfoTooltip = ({
    title = '',
    children,
    className = null,
    buttonClassName,
    contentClassName,
    direction
}) => {
    const [isVisible, setVisiblity] = useState(false)

    const ref = useRef(null)

    const toggleToolTip = () => {
        setVisiblity(!isVisible)
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setVisiblity(false)
        }
    }

    const handleMouseOutside = () => {
        setVisiblity(false)
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)

        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    const classes = classNames(styles.infoTooltip, className)
    const buttonClasses = classNames(styles.infoTooltipButton, buttonClassName)
    const contentClasses = classNames(styles.infoTooltipContent, styles[`infoTooltipContent--${direction}`], {
        [`${styles['infoTooltipContent--hidden']}`]: !isVisible
    }, contentClassName)

    return (
        <aside className={classes} ref={ref}>
            <button
                aria-label="Toggle Tool Tip"
                className={buttonClasses}
                onMouseEnter={toggleToolTip}
                onMouseLeave={handleMouseOutside}
                onClick={toggleToolTip}
                type="button">
                <SvgSprite spriteID='icon-tooltip-default' />
            </button>
            <div className={contentClasses}>
                { title && <span className={styles.infoTooltipTitle}>{title}</span> }
                <div className={styles.infoTooltipText}>
                    { children }
                </div>
            </div>
        </aside>
    )
}

InfoTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    buttonClassName: PropTypes.string,
    contentClassName: PropTypes.string
}

export default InfoTooltip
