import { CLASSIC_STARTER_BUNDLE, FOUNDATION, ADJUSTABLE_FRAME, SAATVA_CLASSIC, ORGANIC_MATTRESS_PAD } from '@/constants/product-codes'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import styles from './BundlesProductSelectors.module.scss'

// Currently supported `displayData` overrides include:
//      name
//      description
//      url
//      imageName
//      imageFolder
//      attributeLabels

// `displayData` should have identical structure for merge products as it does
// for the individal products (the latter accessed via a product code key).

// `attributeLabels` is an object allowing you to override the displayName
// for product options using the following structure:
//      `{ <attributeCode>: <desiredLabelString> }`

const config = {
    [CLASSIC_STARTER_BUNDLE]: {
        displayData: {
            [SAATVA_CLASSIC]: {
                name: <span>Saatva Classic Mattress <SvgSprite className={styles.awardSprite} spriteID='icon-award-winning' /></span>,
                description: <ul>
                    <li>America's best-selling online luxury innerspring</li>
                    <li>3" Euro pillow top</li>
                    <li>Durable & responsive dual-coil design</li>
                </ul>,
                attributeLabels: {
                    'mattressHeight': 'Height'
                }
            },
            [FOUNDATION]: {
                name: 'Foundation',
                description: <ul>
                    <li>More durable than a traditional box spring</li>
                    <li>Adds optimal support and durability</li>
                </ul>
            },
            [ADJUSTABLE_FRAME]: {
                name: 'Universal Frame',
                description: <ul>
                    <li>Steel frame adjusts to fit any size foundation/box spring</li>
                    <li>Supports and lifts your foundation and mattress 7.5” off the ground</li>
                </ul>
            },
            [ORGANIC_MATTRESS_PAD]: {
                description: <ul>
                    <li>Plush GOTS®-certified organic cotton</li>
                    <li>Breathable comfort and protection from light stains and general wear & tear</li>
                </ul>

            }
        }
    }
}

export default config
