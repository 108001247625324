import * as Sentry from '@sentry/nextjs'
import { initializeLogger } from '@saatva-bits/pattern-library.utils.logger'
// Initialize the logger with Sentry methods and a debug setting
import ENVIRONMENTS from '@/constants/environments'

let loggerInitialized = false

// This needs to be called in the _app.js file and in any API route that want to utilize the logger.
function initLogger() {
    if (!loggerInitialized) {
        initializeLogger({
            sentryCaptureMethods: {
                captureException: Sentry.captureException,
                captureMessage: Sentry.captureMessage
            },
            enableDebugLogs: process.env.NEXT_PUBLIC_APP_ENV !== ENVIRONMENTS.PRODUCTION,
            tagForClientAlerts: {
                name: 'errorSource',
                value: 'saatva'
            }
        })

        loggerInitialized = true
    }
}

export default initLogger
