import { storageWrapper } from '@saatva-bits/pattern-library.utils.storage'
import logger from '@saatva-bits/pattern-library.utils.logger'

const storage = storageWrapper()

const RECENTLY_VIEWED_KEY = 'recentlyViewed'
const STORAGE_TYPE = 'local'

const setRecentlyViewed = (productCode) => {
    if (!productCode) return

    try {
        // get the recently viewed array from the storage
        let recentlyViewed = storage.getItem(RECENTLY_VIEWED_KEY, STORAGE_TYPE) || []

        const thirtyDaysAgo = new Date().getTime() - 30 * 24 * 60 * 60 * 1000

        // remove the productCode from the array if it exists
        // also remove any expired entries (older than 30 days)
        recentlyViewed = recentlyViewed.filter(viewedEntry => {
            return viewedEntry.productCode !== productCode && viewedEntry.timestamp > thirtyDaysAgo
        })

        // add the productCode to the beggining of the array
        recentlyViewed.unshift({ productCode, timestamp: new Date().getTime() })

        // limit the array to 9 items
        recentlyViewed = recentlyViewed.slice(0, 9)

        // set the array to the storage
        storage.setItem(RECENTLY_VIEWED_KEY, recentlyViewed, STORAGE_TYPE)
    } catch (error) {
        // this will only catch errors in the logic above, errors due to localStorage availability will be caught by the storageWrapper
        // this should only error if the data in the storage is corrupted
        logger.error({
            message: 'Error setting recently viewed product',
            location: 'utils.recentlyView.setRecentlyViewed',
            details: { productCode },
            error,
            enableClientAlerts: true
        })

        // if there is an error clear the storage
        storage.removeItem(RECENTLY_VIEWED_KEY, STORAGE_TYPE)
    }
}
export { setRecentlyViewed }
