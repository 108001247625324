import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { VideoImgix } from '@saatva-bits/pattern-library.components.video-imgix'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { PRODUCT_CODES } from '@/constants'
import styles from './VideoBelowPDPCarousel.module.scss'
import { SAATVA_CLASSIC } from '@/constants/product-codes'

const IMGIX_VIDEO_URL = process.env.NEXT_PUBLIC_IMGIX_VIDEO_URL

const VideoBelowPDPCarousel = ({ className, productCode }) => {
    const { isMobile } = useBreakpoints('mobile')
    const { isDesktop } = useBreakpoints('desktop')
    const standardLayout = productCode === SAATVA_CLASSIC && isDesktop
    const enabledProducts = [PRODUCT_CODES.SAATVA_CLASSIC, PRODUCT_CODES.CLASSIC_STARTER_BUNDLE]
    const isEnabledOnProduct = enabledProducts.includes(productCode)
    const aspectRatio = isMobile ? '1-1' : '16-9'
    if (!isEnabledOnProduct || !standardLayout) return null
    const videoClasses = classNames(styles.video, className)
    const lazyLoadContainerClasses = classNames(className, 'pdpVideoLazyLoadContainer')

    return (
        <VideoImgix
            aspectRatio={aspectRatio}
            className={videoClasses}
            dataSelector='saatva-classic-pdp-video'
            extension='mp4'
            filename='responsiveness-back'
            folder='saatva-classic'
            imgixOptions={{
                fm: 'mp4'
            }}
            imgixSubdomain={IMGIX_VIDEO_URL}
            lazyLoadOffset={1}
            lazyLoadContainerClassName={lazyLoadContainerClasses}
            type='video/mp4'
            videoProps={{
                autoPlay: true,
                muted: true,
                loop: true,
                playsInline: true,
                preload: 'auto', // needs to be auto for Safari
                // 16:9 aspect ratio to reduce CLS, won't actually be this big due to CSS
                width: 1600,
                height: 900
            }}
        />
    )
}

VideoBelowPDPCarousel.propTypes = {
    productCode: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default VideoBelowPDPCarousel
