import { Footer as BitFooter } from '@saatva-bits/pattern-library.modules.footer'
import { open as openChat } from '@saatva-bits/pattern-library.utils.chat'
import { handleCordialEmailSubmission } from '@/utils/cordial'

import styles from './Footer.module.scss'

export default function Footer({ footerData }) {
    return (
        <BitFooter
            data={footerData}
            emailSubmitHandler={handleCordialEmailSubmission}
            openChat={openChat}
            className={styles.container}
        />
    )
}
