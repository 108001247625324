import dynamic from 'next/dynamic'
import StandardContent from './StandardContent'
import { PRODUCT_COLLECTIONS, PRODUCT_CODES, SUBCATEGORIES } from '@/constants'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import useEECProductDetails from '@/hooks/useEECProductDetails'

// These string keys are hardcoded because Next/Webpack do not support variable interpolation in dynamic imports
// We could use the constants from the PRODUCT_CODES file, but we've chosen to make them explicit to ensure
// that if we have to change a product code, we have to change the import path string as well.
const pdpContent = {
    // Mattresses
    [PRODUCT_CODES.SAATVA_CLASSIC]: dynamic(() => import('./mattresses/saatva-classic')),
    [PRODUCT_CODES.BUNK_TRUNDLE_YOUTH]: dynamic(() => import('./mattresses/bunk-trundle-youth')),
    [PRODUCT_CODES.CRIB_MATTRESS]: dynamic(() => import('./mattresses/crib-mattress')),
    [PRODUCT_CODES.DOG_BED]: dynamic(() => import('./mattresses/dog-bed')),
    [PRODUCT_CODES.LOOM_AND_LEAF]: dynamic(() => import('./mattresses/loom-and-leaf')),
    [PRODUCT_CODES.SAATVA_HD]: dynamic(() => import('./mattresses/saatva-hd')),
    [PRODUCT_CODES.MEMORY_FOAM_HYBRID]: dynamic(() => import('./mattresses/memory-foam-hybrid')),
    [PRODUCT_CODES.SAATVA_CONTOUR5]: dynamic(() => import('./mattresses/saatva-contour5')),
    [PRODUCT_CODES.SAATVA_LATEX_HYBRID]: dynamic(() => import('./mattresses/saatva-latex-hybrid')),
    [PRODUCT_CODES.SAATVA_RX]: dynamic(() => import('./mattresses/saatva-rx')),
    [PRODUCT_CODES.SAATVA_YOUTH]: dynamic(() => import('./mattresses/saatva-youth')),
    [PRODUCT_CODES.SOLAIRE]: dynamic(() => import('./mattresses/solaire')),
    [PRODUCT_CODES.ZENHAVEN]: dynamic(() => import('./mattresses/zenhaven')),

    // Furniture
    [PRODUCT_CODES.FOUNDATION]: dynamic(() => import('./furniture/foundation')),
    [PRODUCT_CODES.ADJUSTABLE_BASE]: dynamic(() => import('./furniture/adjustable-base')),
    [PRODUCT_CODES.ADJUSTABLE_BASE_PLUS]: dynamic(() => import('./furniture/adjustable-base-plus')),

    // Bedframes
    [PRODUCT_CODES.SIENA_LEATHER]: dynamic(() => import('./furniture/bedFrames/siena-leather')),
    [PRODUCT_CODES.ADRA_LEATHER]: dynamic(() => import('./furniture/bedFrames/adra-leather')),


    // Bedding will go here
    [PRODUCT_CODES.THE_SAATVA_PILLOW]: dynamic(() => import('./bedding/pillows/the-saatva-pillow')),
    [PRODUCT_CODES.ORGANIC_QUILTED_PILLOW]: dynamic(() => import('./bedding/pillows/organic-quilted-pillow')),
    [PRODUCT_CODES.MEMORY_FOAM_PILLOW]: dynamic(() => import('./bedding/pillows/memory-foam-pillow')),
    [PRODUCT_CODES.DOWN_ALTERNATIVE_PILLOW]: dynamic(() => import('./bedding/pillows/down-alternative-pillow')),
    [PRODUCT_CODES.CLOUD_MEMORY_FOAM_PILLOW]: dynamic(() => import('./bedding/pillows/cloud-memory-foam-pillow')),
    [PRODUCT_CODES.SILK_PILLOWCASE]: dynamic(() => import('./bedding/pillowcases/silk-pillowcase')),
    // Pads and Toppers
    [PRODUCT_CODES.LATEX_MATTRESS_TOPPER]: dynamic(() => import('./bedding/padsAndToppers/latex-mattress-topper')),
    [PRODUCT_CODES.WATERPROOF_MATTRESS_PROTECTOR]: dynamic(() => import('./bedding/padsAndToppers/waterproof-mattress-protector')),
    [PRODUCT_CODES.ORGANIC_QUILTED_MATTRESS_TOPPER]: dynamic(() => import('./bedding/padsAndToppers/organic-quilted-mattress-topper')),
    [PRODUCT_CODES.ORGANIC_MATTRESS_PAD]: dynamic(() => import('./bedding/padsAndToppers/organic-mattress-pad')),

    // Bundles
    [PRODUCT_CODES.CLASSIC_STARTER_BUNDLE]: dynamic(() => import('./bundles/classic-starter-bundle')),
    [PRODUCT_CODES.SATEEN_SHEETS_LATEX_PILLOW]: dynamic(() => import('./bundles/sateen-sheets-latex-pillow')),
    [PRODUCT_CODES.SATEEN_SHEETS_LATEX_PILLOW_PAD]: dynamic(() => import('./bundles/sateen-sheets-latex-pillow-pad')),
    [PRODUCT_CODES.SATEEN_SHEETS_MEMORY_FOAM_PILLOW]: dynamic(() => import('./bundles/sateen-sheets-memory-foam-pillow')),

    // Benches
    [PRODUCT_CODES.CONSTANCE_TUFTED_OTTOMAN]: dynamic(() => import('./furniture/benches/constance-tufted-ottoman')),
    [PRODUCT_CODES.ARNO_CUSHIONED_BENCH]: dynamic(() => import('./furniture/benches/arno-cushioned-bench')),
    [PRODUCT_CODES.BRIENNE_CHANNEL_OTTOMAN]: dynamic(() => import('./furniture/benches/brienne-channel-ottoman')),
    [PRODUCT_CODES.RHONE_STORAGE_BENCH]: dynamic(() => import('./furniture/benches/rhone-storage-bench')),
    [PRODUCT_CODES.LENA_CUSHIONED_BENCH]: dynamic(() => import('./furniture/benches/lena-cushioned-bench')),

    // Chairs
    [PRODUCT_CODES.ANAIS_CHAIR]: dynamic(() => import('./furniture/chairs/anais-chair')),
    [PRODUCT_CODES.COMO_SWIVEL_CHAIR]: dynamic(() => import('./furniture/chairs/como-swivel-chair')),
    [PRODUCT_CODES.LOGAN_CHAIR]: dynamic(() => import('./furniture/chairs/logan-chair')),
    [PRODUCT_CODES.LUNA_SWIVEL_CHAIR]: dynamic(() => import('./furniture/chairs/luna-swivel-chair')),

    // Bedding
    [PRODUCT_CODES.LINEN_PILLOWCASES]: dynamic(() => import('./bedding/pillowcases/linen-pillowcases'))
}

// Configurations for subcategories
const subcategoryContent = {
    // Furniture Subcategories
    [SUBCATEGORIES.BED_FRAMES]: dynamic(() => import('./furniture/bedFrames/BedFrameContent')),
    [SUBCATEGORIES.PLATFORM_BASES]: dynamic(() => import('./furniture/bedFrames/BedFrameContent')),
    [SUBCATEGORIES.RUGS]: dynamic(() => import('./furniture/rugs/RugsContent')),
    [SUBCATEGORIES.CANDLES]: dynamic(() => import('./furniture/candles/CandlesContent')),
    [SUBCATEGORIES.NIGHTSTANDS]: dynamic(() => import('./furniture/nightstands/NightstandsContent')),

    // Bedding Subcategories
    [SUBCATEGORIES.PILLOWCASES_AND_SHAMS]: dynamic(() => import('./bedding/pillowcases/PillowcaseContent')),
    [SUBCATEGORIES.MATTRESS_TOPPERS]: dynamic(() => import('./bedding/padsAndToppers/ToppersContent')),
    [SUBCATEGORIES.MATTRESS_PADS]: dynamic(() => import('./bedding/padsAndToppers/ToppersContent')),
    [SUBCATEGORIES.DUVETS]: dynamic(() => import('./bedding/duvetsAndComforters/DuvetsContent')),
    [SUBCATEGORIES.COMFORTERS]: dynamic(() => import('./bedding/duvetsAndComforters/DuvetsContent')),
    [SUBCATEGORIES.SHEETS]: dynamic(() => import('./bedding/sheets/SheetsContent')),
    [SUBCATEGORIES.BLANKETS]: dynamic(() => import('./bedding/blanketsAndQuilts/BlanketsAndQuiltsContent')),
    [SUBCATEGORIES.TOWELS]: dynamic(() => import('./bedding/towels/towelsContent')),
    [SUBCATEGORIES.QUILTS]: dynamic(() => import('./bedding/blanketsAndQuilts/BlanketsAndQuiltsContent')),
    [SUBCATEGORIES.EYE_MASKS]: dynamic(() => import('./bedding/eyeMasks/EyeMasksContent'))
}

// get content for PDP
const ContentComponent = (productCode) => {
    const { subcategory } = useProductData(productCode)

    // Default to product content config
    if (pdpContent[productCode]) {
        return pdpContent[productCode]
    }

    // fallback on subcategory content config
    if (subcategoryContent[subcategory]) {
        return subcategoryContent[subcategory]
    }

    // If not, content can be loaded
    return null
}

export default function Content({
    productCode
}) {
    {/* Mattresses wrap the StandardContent in sections for the sticky nav, so we exclude those */ }
    const excludeStandardContent = PRODUCT_COLLECTIONS.MATTRESSES.includes(productCode) || PRODUCT_COLLECTIONS.BEDFRAMES.includes(productCode) || productCode === PRODUCT_CODES.CLASSIC_STARTER_BUNDLE
    const includeStandardContent = !excludeStandardContent
    useEECProductDetails(productCode)

    const CustomContent = ContentComponent(productCode)
    return CustomContent ? (
        <>
            <CustomContent productCode={productCode} />
            {includeStandardContent && <StandardContent productCode={productCode} />}
        </>
    ) : (
        <StandardContent productCode={productCode} />
    )
}
