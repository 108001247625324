import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import styles from './ProductPromoCallout.module.scss'
import { BED_FRAMES, RUGS } from '@/constants/subcategories'

const ProductPromoCallout = ({
    productCode,
    className
}) => {
    const { surplusDiscountDefinitions } = usePromotionsData()
    const { subcategory } = useProductData(productCode)

    // This is not relevant for fixed_item discounts
    const showCallout = surplusDiscountDefinitions[productCode]?.discountValueType === 'percent'
    const discountAmount = surplusDiscountDefinitions[productCode]?.discount

    const classes = classNames(
        styles.ribbon,
        'u-marginVertical--md',
        't-bodySm',
        't-bold',
        className
    )
    let discountClarification = ''
    switch(subcategory){
        case BED_FRAMES:
            discountClarification = 'Select'
            break
        case RUGS:
            discountClarification = 'All'
            break
        default:
            break
    }

    return showCallout && (
        <div className={classes}>
            Extra {discountAmount}% Off {discountClarification} {subcategory}
        </div>
    )
}

ProductPromoCallout.propTypes = {
    productCode: PropTypes.string,
    className: PropTypes.string
}

export default ProductPromoCallout
