import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { config } from './VideoByContentConfig'
import { VideoImgix } from '@saatva-bits/pattern-library.components.video-imgix'
import useDeviceType from '@/hooks/useDeviceType'
import styles from './VideoByContent.module.scss'

const IMGIX_VIDEO_URL = process.env.NEXT_PUBLIC_IMGIX_VIDEO_URL

const VideoByContent = ({
    backgroundColorClass = 'u-bgColor--contrast2',
    className,
    contentClassName = '',
    titleClassName = '',
    videoSide = 'right',
    productCode,
    sectionName,
    titleBelowVideo = false,
    sectionId = undefined
}) => {
    const { isMobile } = useDeviceType()
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0)

    const sectionClasses = classNames('section', className, backgroundColorClass)
    const videoClasses = classNames(styles.videoContainer, 'col--md-6 col--xs-12')
    const contentClasses = classNames(styles.contentContainer, 'col--md-6 col--xs-12 u-textLeft', contentClassName, {
        [styles['contentContainer--paddingRight']]: videoSide === 'right',
        [styles['contentContainer--paddingLeft']]: videoSide === 'left'
    })

    const productData = config[productCode]

    const title = () => {
        const titleClasses = classNames('t-heading2', styles.title, titleClassName)
        return <h2 className={titleClasses} dangerouslySetInnerHTML={{ __html: productData.title }}></h2>
    }

    const content = () => {
        if (productData.hasMultipleVideos) {
            const Component = productData.content
            return <Component productCode={productCode} setCurrentImageIndex={setCurrentVideoIndex} isInsetContent={false} hideTabTitles={productData.hideTabTitles} />
        }

        const productContent = productData.content()
        return productContent.map((item, index) => {
            const key = `${sectionName}-content-${index}`
            return typeof item === 'string'
                ? <p key={key}>{item}</p>
                : <React.Fragment key={key}>{item}</React.Fragment>
        })
    }

    const video = () => {
        const changeImage = productData.hasMultipleVideos

        return (
            <VideoImgix
                aspectRatio='1-1'
                className={styles.video}
                extension='mp4'
                folder={changeImage ? productData.videoFolder[currentVideoIndex] : productData.videoFolder}
                filename={changeImage ? productData.videoName[currentVideoIndex] : productData.videoName}
                imgixOptions={{
                    fm: 'mp4'
                }}
                imgixSubdomain={IMGIX_VIDEO_URL}
                lazyLoadOffset={200}
                type='video/mp4'
                videoProps={{
                    autoPlay: true,
                    muted: true,
                    loop: true,
                    playsInline: true,
                    preload: 'auto', // needs to be auto for Safari
                    width: 1280,
                    height: 720
                }}
            />
        )
    }

    const mobileView = () => {
        if (titleBelowVideo) {
            return (
                <>
                    <div className={videoClasses}>
                        {video()}
                    </div>
                    <div className={contentClasses}>
                        {title()}
                    </div>
                    <div className={contentClasses}>
                        {content()}
                    </div>
                </>
            )
        }
        return (
            <>
                <div className={contentClasses}>
                    {title()}
                </div>
                <div className={videoClasses}>
                    {video()}
                </div>
                <div className={contentClasses}>
                    {content()}
                </div>
            </>
        )
    }

    const sideBySideView = () => {
        const contentSection = (
            <>
                <div className={contentClasses}>
                    {title()}
                    {content()}
                </div>
            </>
        )
        const videoSection = (
            <>
                <div className={videoClasses}>
                    {video()}
                </div>
            </>
        )

        return (
            <>
                {videoSide === 'right'
                    ? [contentSection, videoSection]
                    : [videoSection, contentSection]
                }
            </>
        )
    }

    return (
        <section className={sectionClasses} id={sectionId}>
            <div className="container row">
                { isMobile && mobileView() }
                { !isMobile && sideBySideView() }
            </div>
        </section>
    )
}

VideoByContent.propTypes = {
    backgroundColorClass: PropTypes.string,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    videoSide: PropTypes.oneOf(['right', 'left']),
    productCode: PropTypes.string,
    sectionName: PropTypes.string,
    titleBelowVideo: PropTypes.bool,
    sectionId: PropTypes.string
}

export default VideoByContent
