import React, { useEffect, useState } from 'react'
import { string } from 'prop-types'
import styles from './ProductCarouselWrapper.module.scss'

// import components
import { ProductCarousel } from '@saatva-bits/pattern-library.components.product-carousel'
import ProductCarouselGallery from '@/components/ProductCarouselGallery'
import EpigraphModelViewer from '@/components/EpigraphModelViewer'
import AffiliateBadge from '@/components/AffiliateBadge'

// import helpers
import get from 'lodash/get'
import classNames from 'classnames'
import { useProductState, useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { generateAssetURLs, filterARImages } from '@saatva-bits/pattern-library.utils.product'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'
import useDeviceType from '@/hooks/useDeviceType'
import { createImgixVideo, createImgixVideoThumbnail } from '@/utils/pdpCarousel'

// import configs
import carouselData from '@/temp-configs/carousel-config'
import { SAATVA_CLASSIC } from '@/constants/product-codes'
import videoConfig from '@/components/ProductCarouselGallery/carouselVideoConfig'
import { getAssetForVariant } from '@saatva-bits/pattern-library.modules.selection'
import logger from '@saatva-bits/pattern-library.utils.logger'

const ProductCarouselWrapper = ({
    productCode
}) => {
    const { isV1: showLayerDiagram } = useExperiment('EXP.LAYER_PDP_GALLERY.EX-172')
    const { isDesktop } = useDeviceType('desktop')
    const productState = useProductState(productCode, [])
    const { options, content } = useProductData(productCode)
    const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

    const assetData = get(carouselData, productCode, undefined)

    const hasLayerDiagramImg = showLayerDiagram && productCode === SAATVA_CLASSIC
    if (hasLayerDiagramImg) {
        const descriptor = ['lifestyle-bedroom', 'video']
        assetData.imageDescriptors = assetData.imageDescriptors.filter(item => !descriptor.includes(item))
    } else {
        assetData.imageDescriptors = assetData.imageDescriptors.filter(item => item !== 'layer-diagram')
    }

    // Filter out the video asset to prevent broken images, EX-134
    const filteredAssetData = productCode === SAATVA_CLASSIC
        ? {
            ...assetData,
            imageDescriptors: assetData.imageDescriptors.filter(item => {
                const descriptorName = typeof item !== 'string' ? item.descriptorName : item
                return !descriptorName.includes('video')
            })
        } : assetData

    // This shows the alternate layout just in mobile/tablet for saatva-classic
    const isSaatvaClassic = productCode === SAATVA_CLASSIC 
    const hasAlternateLayout = !isDesktop && isSaatvaClassic
    // TODO: Refactor to avoid displaying image resizing in Tablet view. (DeviceType is no totally reliable to identfy real tablets since the user-agent for most tablets is "desktop" now)
    const [isLandscape, setIsLandscape] = useState(false)
    const isMobileOrTablet = !isDesktop
    const heroImageAspectRatio = isMobileOrTablet ? hasAlternateLayout ? '3-2' : '16-9' : hasAlternateLayout ? '4-3' : '3-2'
    const zoomImageAspectRatio = isLandscape ? '16-9' : isMobileOrTablet ? '4-3' : '16-9'
    const thumbnailAspectRatio = hasAlternateLayout && isDesktop ? '1-1' : '3-2'
    const isBedFrameProduct = productState?.subcategory.toLowerCase() === 'bed frames' || productState?.subcategory.toLowerCase() === 'platform bases'

    const createAssetDrivenImages = (productCode, assetData, productState, zoomGallery = false) => {
        if (!assetData) return null
        const filterARImagesOut = isMobileOrTablet && (isBedFrameProduct && !zoomGallery)
        const aspectRatios = zoomGallery ? zoomImageAspectRatio : heroImageAspectRatio

        // Filter out the video asset to prevent broken images, EX-134
        const selectedAssetData = productCode === SAATVA_CLASSIC && isDesktop
            ? filteredAssetData
            : assetData
        let heroImages = []
        let thumbnailImages = []

        const ardadDescriptors = content?.ardadDescriptors
        const carouselDescriptors = ardadDescriptors?.find( descriptorDef => descriptorDef.slot == 'carousel')

        if (!carouselDescriptors) { // If there are not descriptiors in prismic, render the old ardad assets config
            heroImages = generateAssetURLs(productCode, selectedAssetData, productState, aspectRatios)
            thumbnailImages = generateAssetURLs(productCode, selectedAssetData, productState, thumbnailAspectRatio)
        } else {
            heroImages = ardadImages(productState, carouselDescriptors.descriptors, aspectRatios)
            thumbnailImages = ardadImages(productState, carouselDescriptors.descriptors, thumbnailAspectRatio)
        }

        heroImages = filterARImages(heroImages, filterARImagesOut)
        thumbnailImages = filterARImages(thumbnailImages, filterARImagesOut)

        const altTag = assetData?.altTag
        const imgixOptionsOverrides = !zoomGallery && { auto: 'format,compress' }

        return {
            heroImages,
            thumbnailImages,
            altTag,
            imgixOptionsOverrides
        }
    }
    const ardadImages = (productState, descriptors, aspectRatios) => {
        const images = []

        descriptors.forEach(descriptor => {
            try {
                const { folder, file } = getAssetForVariant(productState, descriptor, aspectRatios)
                images.push({
                    folder,
                    filename: file
                })
            } catch (err) {
                logger.debug({
                    message: 'Cannot find ARDAD asset for variant',
                    location: 'components.ProductCarouselWrapper.createAssetDrivenImages.ardadImages',
                    details: {
                        productCode: productState.productCode,
                        sku: productState.sku,
                        descriptor
                    },
                    err
                })
            }
        })

        return images
    }

    const [assetDrivenImages, setAssetDrivenImages] = useState(createAssetDrivenImages(productCode, assetData, productState))

    useEffect(() => {
        setAssetDrivenImages(createAssetDrivenImages(productCode, assetData, productState))
        if (window) {
            setIsLandscape(window.matchMedia('(orientation: landscape)').matches)
        }
    }, [productState, isMobileOrTablet ])

    const productZoomGalleryData = createAssetDrivenImages(productCode, filteredAssetData, productState, true)

    if (!assetDrivenImages) return null // short circuit if no images

    const videoDataConfig = videoConfig[productCode]
    let videoData = {}
    if (videoDataConfig) {
        if (isMobileOrTablet) videoDataConfig.aspectRatio = '3-2'
        const imgxVideoThumbnail = createImgixVideoThumbnail(videoDataConfig, undefined, '', true, true)
        const imgxVideo = createImgixVideo(videoDataConfig, videoDataConfig.carouselIndex, 'product-carousel-video')
        videoData = {
            videoThumbnail: imgxVideoThumbnail,
            videoChild: imgxVideo
        }
    }

    const sliderContainerClasses = classNames(styles.containerBreakout, {
        'u-hidden--lg-up': hasAlternateLayout,
    })

    // Return both carousels on the server side and let CSS handle display based on resolution
    // This avoids flash of desktop carousel on mobile/tablet and reduces layout shift for EX-134 test.
    return (
        <ProductCarouselGallery
            arChild={<EpigraphModelViewer productCode={productCode}/>}
            category={productState?.category}
            imgixDomain={IMGIX_URL}
            productCode={productCode}
            productImageData={productZoomGalleryData}
        >
            <ProductCarousel
                productImageData={assetDrivenImages}
                sliderContainerClasses={sliderContainerClasses}
                imgixDomain={IMGIX_URL}
                arChild={<EpigraphModelViewer productCode={productCode}/>}
                productState={productState}
                productOptions={options}
                assetData={assetData}
                aspectRatio={heroImageAspectRatio}
                productCode={productCode}
                alwaysShowThumbnails={hasAlternateLayout}
                showHighlight={hasAlternateLayout}
                videoData={videoData}
                thumbnailsClasses={showLayerDiagram && isSaatvaClassic ? styles.showLayerDiagram : ''}
                overflow='hidden'
            >
                <AffiliateBadge productCode={productCode} />
            </ProductCarousel>
        </ProductCarouselGallery>
    )
}

ProductCarouselWrapper.propTypes = {
    productCode: string
}

export default ProductCarouselWrapper
