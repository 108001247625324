import { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { node, string, bool, object, shape } from 'prop-types'
import { ScrollToTopButton } from '@saatva-bits/pattern-library.components.scroll-to-top-button'
import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly'
import { LaunchDarklyWidget } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.components'
import { PromotionBanner, usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import { useChatAutoPopup } from '@saatva-bits/pattern-library.utils.chat'
import { storageWrapper } from '@saatva-bits/pattern-library.utils.storage'

import Nav from '@/layouts/Nav'
import Footer from '@/layouts/Footer'

import useDeferScripts from '@/hooks/useDeferScripts'

import { getDataLayerData } from '@/utils/datalayer'
import { navigationDataType, viewingRoomDataType } from '@/types'
import Breadcrumbs from '@/components/Breadcrumbs'
import { getGoogleAnalyticsUserIdFromCookie } from '@/utils/algolia'
import { MATTRESSES } from '@/constants/product-collections'
import { setRecentlyViewed } from '@/utils/recentlyView'

import styles from './Default.module.scss'

const API_PATH = `${process.env.NEXT_PUBLIC_BASE_PATH}/api`
const algoliaSearchIndex = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX
export default function DefaultLayout({
    children,
    applyDoNotIndex,
    description,
    title,
    navigationData,
    footerData,
    launchDarklyContextData,
    openGraphValues,
    pageType = '',
    viewingRoomData,
    displayLDWidget,
    displaySiteConfig,
    customCanonical,
    showBanner = true,
    breadcrumbs,
    productCode,
    mktChannel
}) {
    let promotionData = usePromotionsData()
    useDeferScripts()
    useChatAutoPopup(90000)
    const { asPath } = useRouter()

    useEffect(() => {
        // Instana pages config
        if (typeof window !== 'undefined') {
            window.ineum?.('page', window.location.pathname)

            window.dataLayer.push({
                event: 'pdpView',
                algoliaUserToken: getGoogleAnalyticsUserIdFromCookie(),
                pdpProductCode: productCode,
                searchIndexName: algoliaSearchIndex
            })

            setRecentlyViewed(productCode)
        }
    }, [])

    const { experiments, flagOverrides } = launchDarklyContextData

    const dataLayerData = getDataLayerData(pageType, experiments, flagOverrides)

    const path = customCanonical || asPath.split('#')[0].split('?')[0].replace(/\/$/, '')
    const canonicalUrlPath = `https://www.${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}${path}`

    const isMattressPage = MATTRESSES.includes(productCode)
    const openGraphTags = openGraphValues ? (
        Object.keys(openGraphValues).map((og, i) => <meta key={`og${i}`} property={`og:${og}`} content={openGraphValues[og]} />)
    ) : null

    /*
        Set session storage key for Cordial to act on.
        Any user with marketing url params should see the pop up, otherwise no users should ever see the pop up
    */
    useEffect(() => {
        const storage = storageWrapper()
        const sessionStorageKey = 'blockPromoPopup'

        try {
            if (storage.getItem(sessionStorageKey) === null) {
                const marketingParams = [
                    'utm_source',
                    'utm_medium',
                    'utm_campaign',
                    'utm_term',
                    'utm_content',
                    'cq_src',
                    'cq_cmp',
                    'gclid',
                    'gclsrc'
                ]
                const urlParams = new URLSearchParams(window.location.search)
                const referredByMarketing = marketingParams.some((param) => urlParams.has(param))
                /* add window.location paths to `isBlocked` check to explicitly allow/block */
                const isBlocked = !referredByMarketing
                storage.setItem(sessionStorageKey, isBlocked)
            }
        } catch {}
    }, [])

    const { isOn: enableCSRPersonalization } = useFeatureFlag('ENABLE_CSR_P13N.DIS-377')

    const csrPersonalization = {
        enabled: enableCSRPersonalization,
        marketChannel: mktChannel
    }

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="format-detection" content="telephone=no"></meta>
                <meta name="description" content={description || 'The highest quality handcrafted mattresses, bedding & furniture at the most comfortable prices.'} />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {applyDoNotIndex && <meta name="robots" content="noindex" />}
                {openGraphTags}
                <link rel="icon" sizes="64x64" href="/favicon.png" />
                <link rel="icon" type="image/png" sizes="64x64" href="/favicon.png" />
                <link rel="apple-touch-icon" sizes="64x64" href="/favicon.png" />
                <link rel="apple-touch-icon" href="/favicon.png" />
                <meta name="msapplication-TileColor" content="#FFFFFF" />
                <meta name="msapplication-TileImage" content="/favicon.png" />
                <link rel="canonical" href={canonicalUrlPath} />
                <script dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(${JSON.stringify(dataLayerData)});
                    `
                }} />
            </Head>
            <main>
                {showBanner && <PromotionBanner promotionData={promotionData} csrPersonalization={csrPersonalization} />}
                <Nav
                    navigationData={navigationData}
                    viewingRoomData={viewingRoomData}
                />
                {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                {children}
                <ScrollToTopButton
                    footerAware={!isMattressPage}
                    positionClass={isMattressPage ? styles.backToTopWithStickyNav : ''}
                />
                <Footer footerData={footerData} />
                {displayLDWidget &&
                    <LaunchDarklyWidget flagApiUrl={`${API_PATH}/flag-data`} tokenApiUrl={`${API_PATH}/edge-token`} advanced={displaySiteConfig} bypassCacheParam={null} />
                }
            </main>
        </>
    )
}

DefaultLayout.propTypes = {
    children: node.isRequired,
    applyDoNotIndex: bool,
    description: string,
    title: string.isRequired,
    navigationData: navigationDataType,
    launchDarklyContextData: shape({
        experiments: object,
        featureFlags: object,
        flagOverrides: object
    }),
    pageType: string,
    viewingRoomData: viewingRoomDataType,
    displayLDWidget: bool,
    displaySiteConfig: bool,
    productCode: string,
    mktChannel: string,
}
