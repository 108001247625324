import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { BidirectionalTab, BidirectionalTabs } from '@/components/BidirectionalTabs'
import tabsConfig from './TabbedContentDisplayConfig'
import useScrollToFaqsAndSpecs from '@/hooks/useScrollToFaqsAndSpecs'
import SvgImage from '@/components/SvgImage'

const TabbedContentDisplay = ({
    productCode,
    setCurrentImageIndex,
    isInsetContent = true,
    hideTabTitles = false,
    defaultActiveIndex = 0
}) => {
    const [activeIndex, setActiveIndex] = useState(defaultActiveIndex)
    const tabScrollRef = useRef(null)
    const { ScrollToFaqsAndSpecsButton } = useScrollToFaqsAndSpecs()

    /*
     * This component shares config content from the `ScaleComfortLevels` "sister-component".
     * Be sure any changes to the expected structure of the config doesn't break the other! 
    */
    const {
        hasAlternateColor = false,
        tabContentWrapperClassName = '',
        tabs
    } = tabsConfig[productCode]

    const tabContent = tabs.map((tab, i) => {
        const tabContentClasses = classNames('tabbedContentDisplay__tabContentWrapper', {
            [tabContentWrapperClassName]: tabContentWrapperClassName,
            'is-active': activeIndex === i
        }, tab.customClass)

        return (
            <div
                role="tabpanel"
                id={`tabbedContentDisplayPanel-${i}`}
                aria-labelledby={`tabbedContentDisplayTab-${i}`}
                className={tabContentClasses}
                key={`tabContent-${i}`}
                tabIndex={0}>
                <div className={isInsetContent ? 'tabbedContentDisplay__tabPanel' : ''}>
                    <h2 className="tabbedContentDisplay__tabHeading">{tab.heading}</h2>
                    <div className='tabbedContentDisplay__tabContent'>
                        {tab.hasScrollLink ? tab.dynamicContent(ScrollToFaqsAndSpecsButton) : tab.content}
                    </div>
                </div>
                {tab.icons &&
                    <div className="tabbedContentDisplay__iconWrapper u-marginTop">
                        {tab.icons.map((icon, i) => {
                            return (
                                <SvgImage
                                    className='tabbedContentDisplay__svg'
                                    imageID={icon}
                                    key={`svg-sprite-${i}`}
                                    alt={`Certification icon for ${icon.replace('icon-certification-', '')}`}
                                />
                            )
                        })}
                    </div>
                }
            </div>
        )
    })

    const handleTabClick = (ariaControl) => {
        // ariaControl argument is coming from the BidirectionalTab.jsx component and is equal to selectedAria
        const currentIndex = Number(ariaControl.substring(ariaControl.length - 1))
        setActiveIndex(currentIndex)
        setCurrentImageIndex(currentIndex)
    }

    const renderTabs = () =>
        <BidirectionalTabs
            onChange={handleTabClick}
            selectedAria={`tabbedContentDisplayPanel-${activeIndex}`}
            sliderBar={false}
            className='tabbedContentDisplay__tabList'>
            {tabs.map((tab, index) => {
                const bidirectionalTabClasses = classNames('tabbedContentDisplay__tabName', {
                    'tabbedContentDisplay__tabName--isActive': activeIndex === index,
                    'tabbedContentDisplay__tabName--blueColor': hasAlternateColor,
                    'tabbedContentDisplay__tabName--blueColor--isActive': (activeIndex === index && hasAlternateColor)
                })
                return (
                    <BidirectionalTab
                        ariaControls={`tabbedContentDisplayPanel-${index}`}
                        id={`tabbedContentDisplayTab-${index}`}
                        className={bidirectionalTabClasses}
                        key={`tabbedContentDisplayTab-${index}${activeIndex}`}>
                        {tab.tabName}
                    </BidirectionalTab>
                )
            })}
        </BidirectionalTabs>

    return (
        <React.Fragment>
            <div className='tabbedContentDisplay'>
                <div className='tabbedContentDisplay__tabListScroll' ref={tabScrollRef}>
                    {!hideTabTitles && renderTabs()}
                </div>
                {tabContent}
            </div>
        </React.Fragment>

    )
}

TabbedContentDisplay.propTypes = {
    setCurrentImageIndex: PropTypes.func,
    productCode: PropTypes.string,
    isInsetContent: PropTypes.bool,
    defaultActiveIndex: PropTypes.number
}

export default TabbedContentDisplay
