import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { config } from './ImageByContentConfig'
import PictureImgix from '@/components/PictureImgix'
import useDeviceType from '@/hooks/useDeviceType'
import styles from './ImageByContent.module.scss'

const ImageByContent = ({
    backgroundColorClass = 'u-bgColor--contrastWhite',
    className,
    contentClassName = '',
    imageSide = 'right',
    productCode,
    sectionName,
    titleBelowImage = false,
    titleClassName = '',
    defaultActiveIndex = 0,
    id
}) => {
    const classes = classNames('section', styles[`imageByContent--${sectionName}`], backgroundColorClass, className)
    const { isMobile } = useDeviceType()
    const [currentImageIndex, setCurrentImageIndex] = useState(defaultActiveIndex)

    const contentClasses = classNames(styles.contentContainer, 'col--md-6 col--xs-12 u-textLeft', contentClassName, {
        [styles['contentContainer--paddingRight']]: imageSide === 'right',
        [styles['contentContainer--paddingLeft']]: imageSide === 'left'
    })

    const imageClasses = classNames(styles.imageContainer, 'col--md-6 col--xs-12')

    const productData = config[productCode]

    if (!productData) return null

    const title = () => {
        const titleClasses = classNames('t-heading2', styles.title, titleClassName)
        return <h2 className={titleClasses} dangerouslySetInnerHTML={{ __html: productData.title }}></h2>
    }

    const content = () => {
        if (productData.hasMultipleImages) {
            const Component = productData.content
            const props = productData.contentProps ?? {}
            return <Component productCode={productCode} setCurrentImageIndex={setCurrentImageIndex} {...props} />
        }

        const productContent = productData.content()
        return productContent.map((item, index) => {
            const key = `${sectionName}-content-${index}`
            return typeof item === 'string'
                ? <p key={key}>{item}</p>
                : <React.Fragment key={key}>{item}</React.Fragment>
        })
    }

    const image = () => {
        const changeImage = productData.hasMultipleImages

        return (
            <PictureImgix
                key={`image-${currentImageIndex}`}
                folder={changeImage ? productData.imageFolder[currentImageIndex] : productData.imageFolder}
                name={changeImage ? productData.imageName[currentImageIndex] : productData.imageName}
                alt={productData.imageAlt}
                widths={{
                    mobile: 335,
                    tablet: 328,
                    desktop: 538
                }}
                prefixOverride={{
                    tablet: 'none',
                    mobile: 'none',
                    desktop: 'none'
                }}
            />
        )
    }

    const mobileView = () => {
        if (titleBelowImage) {
            return (
                <>
                    <div className={imageClasses}>
                        {image()}
                    </div>
                    <div className={contentClasses}>
                        {title()}
                    </div>
                    <div className={contentClasses}>
                        {content()}
                    </div>
                </>
            )
        }
        return (
            <>
                <div className={contentClasses}>
                    {title()}
                </div>
                <div className={imageClasses}>
                    {image()}
                </div>
                <div className={contentClasses}>
                    {content()}
                </div>
            </>
        )
    }

    const sideBySideView = () => {
        const contentSection = (
            <>
                <div className={contentClasses}>
                    {title()}
                    {content()}
                </div>
            </>
        )
        const imageSection = (
            <>
                <div className={imageClasses}>
                    {image()}
                </div>
            </>
        )

        return (
            <>
                {imageSide === 'right'
                    ? [contentSection, imageSection]
                    : [imageSection, contentSection]
                }
            </>
        )
    }

    return (
        <section className={classes} id={id}>
            <div className="container row">
                { isMobile && mobileView() }
                { !isMobile && sideBySideView() }
            </div>
        </section>
    )
}

ImageByContent.propTypes = {
    backgroundColorClass: PropTypes.string,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    imageSide: PropTypes.oneOf(['right', 'left']),
    productCode: PropTypes.string,
    sectionName: PropTypes.string,
    titleBelowImage: PropTypes.bool,
    titleClassName: PropTypes.string,
    defaultActiveIndex: PropTypes.number,
    id: PropTypes.string
}

export default React.memo(ImageByContent)
